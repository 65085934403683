import Menu from "../Menu";
import Avatar from "../Avatar";
import MenuItem from "../MenuItem";
import { useAuth } from "../../auth/AuthContext";
import { useUser } from "../../auth/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { HiLogout, HiSelector, HiUser } from "react-icons/hi";

function ProfileMenu() {
  const { userData, isSuperUser } = useUser();
  const token = localStorage.getItem("token");
  const accessToken = localStorage.getItem("accessToken");
  const refreshToken = localStorage.getItem("refreshToken");
  const navigate = useNavigate();
  const { logout } = useAuth();

  function Logout() {
    if (token || accessToken || refreshToken) {
      logout();
      navigate("/login");
    } else {
      navigate("/login");
    }
  }

  return (
    <Menu
      className="my-6"
      trigger={
        <div
          className="items-center text-left w-full p-2 inline-flex justify-center px-4 py-2 text-sm font-medium border rounded-md text-blue-900 bg-white border-transparent hover:bg-gray-100"
          color="ghost-primary"
        >
          <Avatar className="mr-4"></Avatar>
          <div className="flex-grow">
            <div className="font-semibold">{`${userData?.first_name} ${userData?.last_name}`}</div>
            <div className="text-gray-600 text-sm">{`${
              isSuperUser ? "Admin" : "Notary"
            }`}</div>
          </div>
          <HiSelector />
        </div>
      }
    >
      <MenuItem as={Link} to="/notary/profile">
        <HiUser className="w-5 h-5 mr-2" aria-hidden="true" />
        My Profile
      </MenuItem>
      <div onClick={Logout} className="flex">
        <MenuItem>
          <HiLogout className="w-5 h-5 mr-2" aria-hidden="true" />
          Logout
        </MenuItem>
      </div>
    </Menu>
  );
}

export default ProfileMenu;

import getUserMe from "../actions/me";
import { toast } from "react-toastify";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useAuth } from "../auth/AuthContext";
import { useUser } from "../auth/UserContext";
import { useNavigate } from "react-router-dom";
import welcomeImg from "../images/signoff.webp";
import startAuthentication from "../actions/login";
import React, { useState, FormEvent } from "react";

const LoginPage: React.FC = () => {
  const { setSuperUser, setUserData } = useUser();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  localStorage.removeItem("token");
  const [isLoading, setIsLoading] = useState(false);

  const handleLogin = async (event: FormEvent) => {
    event.preventDefault();
    setIsLoading(true);
    startAuthentication({ username, password })
      .then((response) => {
        setIsLoading(false);
        const { access, refresh } = response.data;
        login(access, refresh);
        getUserMe().then((me: any) => {
          setSuperUser(me.data.is_superuser);
          me.data.is_superuser
            ? setUserData({
                first_name: me.data.first_name,
                last_name: me.data.last_name,
                phone_number: "",
              })
            : setUserData({
                first_name: me.data.notary_profile.first_name,
                last_name: me.data.notary_profile.last_name,
                phone_number: me.data.notary_profile.phone,
              });
          localStorage.setItem("iSU", me.data.is_superuser);
          me.data.is_superuser ? navigate("/admin") : navigate("/notary");
        });
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error("Login failed. Please try again.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  return (
    <div className="flex flex-col h-screen justify-between">
      <section className="mx-5 mt-6 md:mb-24">
        <Header />
        <div
          className="m-8 bg-white rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl m-auto"
          style={{ maxWidth: "1000px" }}
        >
          <div className="g-3 flex flex-wrap items-center justify-center lg:justify-between">
            <div className="md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12">
              <img src={welcomeImg} className="w-full" alt="Login" />
            </div>

            <div className="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
              <form>
                <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300">
                  <p className="mx-4 mb-0 text-center font-semibold">Login</p>
                </div>

                <input
                  id="email"
                  type="email"
                  placeholder="Email"
                  onChange={(e) => setUsername(e.target.value)}
                  className="mb-6 block w-full border border-slate-300 rounded-md shadow-sm px-3 py-2"
                ></input>

                <input
                  id="password"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-6 block w-full border border-slate-300 rounded-md shadow-sm px-3 py-2"
                ></input>

                <div className="mb-6 flex items-center justify-between">
                  <a href="/reset-password">Forgot password?</a>
                </div>

                <div className="text-end">
                  <button
                    type="button"
                    onClick={handleLogin}
                    className="inline-block rounded bg-sky-500 hover:bg-sky-700 px-7 pb-2.5 pt-3 text-sm font-medium text-white text-end"
                    disabled={isLoading}
                  >
                    {isLoading ? "loading..." : "LOGIN"}
                  </button>

                  <p className="mb-0 mt-2 pt-1 text-sm font-semibold">
                    Don't have an account?{" "}
                    <a
                      href="/register"
                      className="transition duration-150 ease-in-out"
                    >
                      Register
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default LoginPage;

import {
  HiCurrencyDollar,
  HiBadgeCheck,
  HiRefresh,
  HiXCircle,
} from "react-icons/hi";

interface DashboardCardsProps {
  value: number;
  cardType: "income" | "confirmed" | "cancelled" | "progress" | "payout";
}

function DashboardCards({ value, cardType }: DashboardCardsProps) {
  function getCardTitle() {
    switch (cardType) {
      case "income":
        return "Income";
      case "confirmed":
        return "Confirmed Closings";
      case "cancelled":
        return "Cancelled Closings";
      case "progress":
        return "In Progress";
      case "payout":
        return "Payout";
    }
  }

  function getCardIcon() {
    switch (cardType) {
      case "income":
      case "payout":
        return (
          <span className="text-lime-400">
            <HiCurrencyDollar className="mr-4" size={35} />
          </span>
        );
      case "confirmed":
        return (
          <span className="text-yellow-400">
            <HiBadgeCheck className="mr-4" size={35} />
          </span>
        );
      case "cancelled":
        return (
          <span className="text-rose-600">
            <HiXCircle className="mr-4" size={35} />
          </span>
        );
      case "progress":
        return (
          <span className="text-blue-400">
            <HiRefresh className="mr-4" size={35} />
          </span>
        );
    }
  }

  if (cardType === "income") {
    return (
      <div className="hidden md:block md:col-span-3 rounded-xl shadow-sm bg-white">
        <div className="p-4">
          <div className="flex justify-between mb-2 font-semibold text-3xl">
            {getCardIcon()}
            <span>{value}</span>
          </div>
          <div className="text-gray-600 text-xs text-right">
            {getCardTitle()}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="md:col-span-3 rounded-xl shadow-sm bg-white">
      <div className="p-4">
        <div className="flex justify-between mb-2 font-semibold text-3xl">
          {getCardIcon()}
          <span>{value}</span>
        </div>
        <div className="text-gray-600 text-xs text-right">{getCardTitle()}</div>
      </div>
    </div>
  );
}

export default DashboardCards;

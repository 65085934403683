import { HiMenu } from "react-icons/hi";
import IconButton from "../../IconButton";
import { useEffect, useState } from "react";
import { Loader, Popup } from "semantic-ui-react";
import { getNotaries } from "../../../actions/notary";
import useBreakpoint from "@restart/hooks/useBreakpoint";
import { FcAdvertising, FcCancel, FcOk } from "react-icons/fc";

interface AllNotariesProps {
  setOpen: (value: boolean) => void;
}

function AllNotaries({ setOpen }: AllNotariesProps) {
  const isSmall = useBreakpoint("sm", "down");
  const [notaries, setNotaries] = useState([]);

  useEffect(() => {
    getNotaries().then((result) => setNotaries(result.data.results));
  }, []);

  if (!notaries) {
    <Loader />;
  }

  return (
    <>
      <main
        className="flex-grow bg-gray-50 text-gray-900"
        // style={{ height: "150vh" }}
      >
        {isSmall && (
          <header className="flex items-center justify-between px-4 py-4">
            <IconButton className="-ml-2" onClick={() => setOpen(true)}>
              <HiMenu />
            </IconButton>
          </header>
        )}
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="font-semibold text-xl">All Notaries</div>
          </div>

          <div className="py-6">
            <div className="rounded-xl shadow-sm bg-white md:col-span-12">
              <div className="p-4 overflow-x-scroll">
                <table className="min-w-full">
                  <thead className="text-sm bg-gray-50 text-gray-500">
                    <tr className="text-sm text-left">
                      <th className="px-4 py-3 whitespace-nowrap">ID</th>
                      <th className="px-4 py-3 whitespace-nowrap">
                        First Name
                      </th>
                      <th className="px-4 py-3 whitespace-nowrap">Last Name</th>
                      <th className="px-4 py-3 whitespace-nowrap">Status</th>
                      <th className="px-4 py-3 whitespace-nowrap">Amount</th>
                      <th className="px-4 py-3 whitespace-nowrap">Actions</th>
                      <th className="px-4 py-3 whitespace-nowrap">
                        Date Created
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {notaries ? (
                      notaries.map((notary: any) => (
                        <tr className="text-sm text-left">
                          <td className="px-4 py-3 whitespace-nowrap">
                            {notary.user}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {notary.first_name}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {notary.last_name}
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                              Active
                            </span>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <span className="font-bold">$ 2500</span>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            <div className="flex gap-1 text-xl">
                              <Popup
                                content={"Send an Update Request"}
                                trigger={<FcAdvertising />}
                              />
                              <Popup
                                content={"Deactivate Notary"}
                                trigger={<FcCancel />}
                              />
                              <Popup
                                content={"Activate Notary"}
                                trigger={<FcOk />}
                              />
                            </div>
                          </td>
                          <td className="px-4 py-3 whitespace-nowrap">
                            {notary.added_date}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="w-full mx-auto text-center py-6">
                        No Notary
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default AllNotaries;

import { createContext, useState, useContext, ReactNode } from "react";

type UserProviderProps = {
  children: ReactNode;
};

type User = {
  first_name: string;
  last_name: string;
  phone_number: string;
};

type UserContextType = {
  isSuperUser: boolean;
  setSuperUser: (superUser: boolean) => void;
  userData: User;
  setUserData: (user: User) => void;
};

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: UserProviderProps) => {
  const [isSuperUser, setIsSuperUser] = useState<any>(() => {
    return localStorage.getItem("iSU") === "true";
  });

  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
  });

  const setSuperUser = (superUser: boolean) => {
    setIsSuperUser(superUser);
  };

  return (
    <UserContext.Provider
      value={{
        isSuperUser,
        setSuperUser,
        setUserData,
        userData: userData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("User not provided");
  }
  return context;
};

import React from "react";
import { ToastContainer } from "react-toastify";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Register from "./pages/Register";
import LoginPage from "./pages/LoginPage";
import UserRegistration from "./components/User";
import { AuthProvider } from "./auth/AuthContext";
import { UserProvider } from "./auth/UserContext";
import PrivateRoute from "./components/PrivateRoute";
import LenderRegistration from "./components/Lender";
import EmployeeRegister from "./pages/Employee/Register";
import DashboardRoutesConfig from "./pages/Dashboard/DashboardRoutesConfig";
import DashboardAdminRoutesConfig from "./pages/Dashboard/DashboardAdminRoutesConfig";

import "react-toastify/dist/ReactToastify.css";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ToastContainer />
      <UserProvider>
        <Router>
          <Routes>
            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <DashboardAdminRoutesConfig setNotary={() => {}} />
                </PrivateRoute>
              }
            />
            <Route
              path="/notary/*"
              element={
                <PrivateRoute>
                  <DashboardRoutesConfig setNotary={() => {}} />
                </PrivateRoute>
              }
            />
            <Route path="/" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<Register setUser={() => {}} />} />
            <Route path="/user/register" element={<UserRegistration />} />
            <Route path="/lender/register" element={<LenderRegistration />} />
            <Route
              path="/company/register"
              element={<Register setUser={() => {}} />}
            />
            <Route path="/employee/register" element={<EmployeeRegister />} />
          </Routes>
        </Router>
      </UserProvider>
    </AuthProvider>
  );
};

export default App;

import { HiDotsVertical, HiMenu } from "react-icons/hi";
import IconButton from "../../IconButton/IconButton";
import useBreakpoint from "@restart/hooks/useBreakpoint";

interface TransactionHistoryProps {
  setOpen: (value: boolean) => void;
}

function TransactionHistory({ setOpen }: TransactionHistoryProps) {
  const isSmall = useBreakpoint("sm", "down");

  return (
    <>
      {/** TODO: review  style={{width: "60%", height: "150vh" }}
       * when route is http://localhost:3000/dashboard/notary/transaction
       */}
      <main
        className={`flex-grow bg-gray-50 text-gray-900`}
        // style={{ height: "150vh" }}
      >
        {isSmall && (
          <header className="flex items-center justify-between px-4 py-4">
            <IconButton className="-ml-2" onClick={() => setOpen(true)}>
              <HiMenu />
            </IconButton>
          </header>
        )}
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="font-semibold text-xl">Transaction History</div>
          </div>

          <div className="py-6">
            <div className="rounded-xl shadow-sm bg-white md:col-span-12">
              <div className="p-4 overflow-x-scroll">
                <table className="min-w-full">
                  <thead className="text-sm bg-gray-50 text-gray-500">
                    <tr className="text-sm text-left">
                      <th className="px-4 py-3 whitespace-nowrap">Date</th>
                      <th className="px-4 py-3 whitespace-nowrap">Actions</th>
                      <th className="px-4 py-3 whitespace-nowrap">Status</th>
                      <th className="hidden md:block px-4 py-3 whitespace-nowrap">
                        ID
                      </th>
                      <th className="px-4 py-3 whitespace-nowrap">Type</th>
                      <th className="px-4 py-3 whitespace-nowrap">Signer(s)</th>
                      <th className="px-4 py-3 whitespace-nowrap">Property</th>
                      <th className="px-4 py-3 whitespace-nowrap">Lender</th>
                      <th className="px-4 py-3 whitespace-nowrap">
                        Quick Action
                      </th>
                      <th className="px-4 py-3 whitespace-nowrap">Payout</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {/* {orders.map((order) => ( */}
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {"12/12/2003"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-blue-100 text-blue-700">
                          In Progress
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">47</td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Hybrid RON eNote
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Richard Smith
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        47W street Cleveland, OH 44000
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Wells Fargo
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="flex justify-between">
                          <div className="rounded-full border-2 border-cyan-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-green-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-yellow-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-red-500 w-5 h-5"></div>
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 525</td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {"12/12/2003"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-orange-100 text-orange-700">
                          Cancelled
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">14</td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Hybrid E Close
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Karen Miller
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        47W street Cleveland, OH 44000
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Mutual of Omaha
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="flex justify-between">
                          <div className="rounded-full border-2 border-cyan-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-green-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-yellow-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-red-500 w-5 h-5"></div>
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 625</td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {"12/12/2003"}
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-green-100 text-green-700">
                          Completed
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">23</td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Ink Sign
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Karen Miller
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        47W street Cleveland, OH 44000
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap font-bold">
                        Bank of America
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="flex justify-between">
                          <div className="rounded-full border-2 border-cyan-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-green-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-yellow-500 w-5 h-5"></div>
                          <div className="rounded-full border-2 border-red-500 w-5 h-5"></div>
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 625</td>
                    </tr>
                    {/* <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        48
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-orange-100 text-orange-700">
                          Cancelled
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 0</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        49
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-green-100 text-green-700">
                          Completed
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 725</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        50
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-blue-100 text-blue-700">
                          In Progress
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 250</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        51
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-blue-100 text-blue-700">
                          In Progress
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 255</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        52
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-blue-100 text-blue-700">
                          In Progress
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 500</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        52
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-green-100 text-green-700">
                          Completed
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 525</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr>
                    <tr className="text-sm text-left">
                      <td className="px-4 py-3 whitespace-nowrap">
                        {Date.now()}
                      </td>
                      <td className="hidden md:block px-4 py-3 whitespace-nowrap">
                        53
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <div className="inline-block font-bold text-xs rounded-full px-3 py-2 bg-green-100 text-green-700">
                          Completed
                        </div>
                      </td>
                      <td className="px-4 py-3 whitespace-nowrap">$ 452</td>
                      <td className="px-4 py-3 whitespace-nowrap">
                        <IconButton>
                          <HiDotsVertical />
                        </IconButton>
                      </td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default TransactionHistory;

import { HiMenu } from "react-icons/hi";
import getUserMe from "../../actions/me";
import { states } from "../Register/utils";
import { useState, useEffect } from "react";
import { Dropdown } from "semantic-ui-react";
import { useUser } from "../../auth/UserContext";
import IconButton from "../../components/IconButton";
import { PhotoIcon } from "@heroicons/react/24/solid";
import useBreakpoint from "@restart/hooks/useBreakpoint";
import { Formik, Form, ErrorMessage, Field } from "formik";
interface NotaryProfileProps {
  setOpen: (value: boolean) => void;
}

function NotaryProfile({ setOpen }: NotaryProfileProps) {
  const isSmall = useBreakpoint("sm", "down");
  const { isSuperUser, userData } = useUser();
  const [fileName, setFileName] = useState<any>(null);
  const [submitting, setSubmitting] = useState(false);
  const [companyState, setCompanyState] = useState("AL");
  const [stateJurisdiction, setStateJurisdiction] = useState<any>([
    "CA",
    "CO",
    "MS",
    "MI",
  ]);
  const [me, setMe] = useState({
    first_name: "",
    last_name: "",
    phone_number: "",
    state_jurisdiction: "",
  });

  useEffect(() => {
    getUserMe().then((me: any) => {
      setMe({
        first_name: userData.first_name,
        last_name: userData.last_name,
        phone_number: userData.phone_number,
        state_jurisdiction: isSuperUser
          ? ""
          : me.data.notary_profile.jurisdiction.split(","),
      });
      if (!isSuperUser) {
        setStateJurisdiction(me.data.notary_profile.jurisdiction.split(","));
      }
    });
  }, [isSuperUser, userData]);

  function handleSubmit(values: any) {
    setSubmitting(true);
  }

  function formatPhoneNumber(phoneNumberString: string, setFieldValue: any) {
    let cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      setFieldValue(
        "phone_number",
        "(" + match[1] + ") " + match[2] + "-" + match[3]
      );
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  }

  return (
    <main
      className="flex-grow bg-gray-50 text-gray-900"
      // style={{ height: "150vh" }}
    >
      {isSmall && (
        <header className="flex items-center justify-between px-4 py-4">
          <IconButton className="-ml-2" onClick={() => setOpen(true)}>
            <HiMenu />
          </IconButton>
        </header>
      )}
      <div className={`col-span-12 ${submitting ? "opacity-20" : ""}`}>
        <div className="m-8 bg-white rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl">
          <Formik
            initialValues={{ ...me, state_jurisdiction: stateJurisdiction }}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values, setFieldValue }) => (
              <Form>
                <div className="space-y-12">
                  <div className="border-b border-gray-900/10 pb-12">
                    <h2 className="text-base font-semibold leading-7 text-gray-900">
                      Update your Profile
                    </h2>

                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="first_name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          First Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="first_name"
                            id="first_name"
                            disabled
                            autoComplete="given-name"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900"
                          />
                        </div>
                        <span className="text-xs font-bold text-red-500">
                          <ErrorMessage name="first_name" />
                        </span>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Last Name
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="last_name"
                            id="last_name"
                            disabled
                            autoComplete="family-name"
                            className="block w-full rounded-md border-0 p-1.5 text-gray-900 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <span className="text-xs font-bold text-red-500">
                          <ErrorMessage name="last_name" />
                        </span>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="last_name"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          Phone Number
                        </label>
                        <div className="mt-2">
                          <Field
                            type="text"
                            name="phone_number"
                            id="phone_number"
                            disabled
                            onBlur={(e: any) =>
                              formatPhoneNumber(e.target.value, setFieldValue)
                            }
                            className="block w-full rounded-md p-1.5 text-gray-900 sm:text-sm sm:leading-6"
                          />
                        </div>
                        <span className="text-xs font-bold text-red-500">
                          <ErrorMessage name="phone_number" />
                        </span>
                      </div>
                      {isSuperUser ? null : (
                        <>
                          <div className="sm:col-span-4">
                            <div className="sm:me-0 md:me-5">
                              <label
                                htmlFor="state_jurisdiction"
                                className="block text-sm font-medium leading-6 text-gray-900"
                              >
                                State Jusrisdiction
                              </label>
                              <div className="mt-2">
                                <Dropdown
                                  id={"state_jurisdiction"}
                                  name={"state_jurisdiction"}
                                  placeholder="States"
                                  fluid
                                  multiple
                                  selection
                                  options={states}
                                  value={stateJurisdiction}
                                  onChange={(_, data) => {
                                    setFieldValue(
                                      "state_jurisdiction",
                                      data.value
                                    );
                                    setStateJurisdiction(data.value);
                                  }}
                                />
                              </div>
                            </div>
                            <span className="text-xs font-bold text-red-500">
                              <ErrorMessage name="state_jurisdiction" />
                            </span>
                          </div>

                          <div className="col-span-full">
                            <label
                              htmlFor="cover-photo"
                              className="block text-sm font-medium leading-6 text-gray-900"
                            >
                              E-notary (pdf only)
                            </label>
                            <div className="mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10">
                              <div className="text-center">
                                <PhotoIcon
                                  className="mx-auto h-12 w-12 text-gray-300"
                                  aria-hidden="true"
                                />
                                <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                  <label
                                    htmlFor="enotary"
                                    className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                  >
                                    <span>
                                      {fileName
                                        ? fileName
                                        : "Upload your e-notary certificate"}
                                    </span>
                                    <input
                                      id="enotary"
                                      name="enotary"
                                      type="file"
                                      className="sr-only"
                                      onChange={(e) => {
                                        e.target.files !== null &&
                                        e.target.files.length > 0
                                          ? setFileName(e.target.files[0].name)
                                          : setFileName(null);
                                      }}
                                    />
                                  </label>
                                  <p className="pl-1">
                                    {fileName ? "" : "or drag and drop"}
                                  </p>
                                </div>
                                <p className="text-xs leading-5 text-gray-600">
                                  PDF up to 10MB
                                </p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {isSuperUser ? null : (
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Company Information
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600"></p>

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-3">
                          <label
                            htmlFor="company_name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Company Name
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="company_name"
                              id="company_name"
                              autoComplete="given-name"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="col-span-full">
                          <label
                            htmlFor="company_street_address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Street Address
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="company_street_address"
                              id="company_street_address"
                              autoComplete="company_street_address"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                          <label
                            htmlFor="company_city"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            City
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="company_city"
                              id="company_city"
                              autoComplete="company_city"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="company_state"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            State
                          </label>
                          <div className="mt-2">
                            <Field
                              name="company_state"
                              as="select"
                              onChange={(e: any) =>
                                setCompanyState(e.target.value)
                              }
                              className="h-9 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option selected value={companyState}>
                                Alabama
                              </option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </Field>
                          </div>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="company_zip_code"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ZIP Code
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="company_zip_code"
                              id="company_zip_code"
                              autoComplete="company_zip_code"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-6 flex items-center justify-end gap-x-6">
                  <button
                    type="button"
                    className="text-sm font-semibold leading-6 text-gray-900"
                    onClick={() => {
                      window.history.go(-1);
                    }}
                  >
                    Go Back
                  </button>
                  {/* <button
                    type="submit"
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Save
                  </button> */}
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </main>
  );
}

export default NotaryProfile;

import {
  add,
  eachDayOfInterval,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isSameMonth,
  isToday,
  parse,
  startOfToday,
  startOfWeek,
} from "date-fns";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid";
import { capitalizeFirstLetter } from "./utils";
import { useState } from "react";
import useBreakpoint from "@restart/hooks/useBreakpoint";
import { HiMenu } from "react-icons/hi";
import IconButton from "../../components/IconButton";

interface CalendarProps {
  setOpen: (value: boolean) => void;
}

function Calendar({ setOpen }: CalendarProps) {
  const isSmall = useBreakpoint("sm", "down");
  const today = startOfToday();
  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const colStartClasses = [
    "",
    "col-start-2",
    "col-start-3",
    "col-start-4",
    "col-start-5",
    "col-start-6",
    "col-start-7",
  ];

  const [currMonth, setCurrMonth] = useState(() => format(today, "MMM-yyyy"));
  let firstDayOfMonth = parse(currMonth, "MMM-yyyy", new Date());

  const daysInMonth = eachDayOfInterval({
    start: startOfWeek(firstDayOfMonth),
    end: endOfWeek(endOfMonth(firstDayOfMonth)),
  });

  const getPrevMonth = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    const firstDayOfPrevMonth = add(firstDayOfMonth, { months: -1 });
    setCurrMonth(format(firstDayOfPrevMonth, "MMM-yyyy"));
  };

  const getNextMonth = (event: React.MouseEvent<SVGSVGElement>) => {
    event.preventDefault();
    const firstDayOfNextMonth = add(firstDayOfMonth, { months: 1 });
    setCurrMonth(format(firstDayOfNextMonth, "MMM-yyyy"));
  };

  return (
    <main
      className="flex-grow bg-gray-50 text-gray-900 w-3/5"
      // style={{ height: "150vh" }}
    >
      {isSmall && (
        <header className="flex items-center justify-between px-4 py-4">
          <IconButton className="-ml-2" onClick={() => setOpen(true)}>
            <HiMenu />
          </IconButton>
        </header>
      )}
      <div className="p-8 h-screen flex items-center justify-center">
        <div className="w-[900px] h-[600px]">
          <div className="flex items-center justify-between">
            <p className="font-semibold text-xl">
              {format(firstDayOfMonth, "MMMM yyyy")}
            </p>
            <div className="flex items-center justify-evenly gap-6 sm:gap-12">
              <ChevronLeftIcon
                className="w-6 h-6 cursor-pointer"
                onClick={getPrevMonth}
              />
              <ChevronRightIcon
                className="w-6 h-6 cursor-pointer"
                onClick={getNextMonth}
              />
            </div>
          </div>
          <hr className="my-6" />
          <div className="grid grid-cols-7 gap-6 sm:gap-12 place-items-center">
            {days.map((day, idx) => {
              return (
                <div key={idx} className="font-semibold">
                  {capitalizeFirstLetter(day)}
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-7 gap-6 sm:gap-12 mt-8 place-items-center">
            {daysInMonth.map((day, idx) => {
              return (
                <div key={idx} className={colStartClasses[getDay(day)]}>
                  <p
                    className={`cursor-pointer flex items-center justify-center font-semibold h-8 w-8 rounded-full  hover:text-white ${
                      isSameMonth(day, today)
                        ? "text-gray-900"
                        : "text-gray-400"
                    } ${!isToday(day) && "hover:bg-blue-500"} ${
                      isToday(day) && "bg-red-500 text-white"
                    }`}
                  >
                    {format(day, "d")}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </main>
  );
}

export default Calendar;

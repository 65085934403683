import axios from 'axios';

// Get the backend base URL from the environment variables
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export default function getUserMe() {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Include the base URL to ensure the request goes to the correct endpoint
  return axios.get(`${BASE_URL}/api/me/`, config);
}

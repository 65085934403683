import { HiMenu } from "react-icons/hi";
import IconButton from "../../components/IconButton";
import useBreakpoint from "@restart/hooks/useBreakpoint";

interface ClosingsProps {
  setOpen: (value: boolean) => void;
}

function Closings({ setOpen }: ClosingsProps) {
  const isSmall = useBreakpoint("sm", "down");

  return (
    <>
      <main
        className="flex-grow bg-gray-50 text-gray-900"
        // style={{ height: "150vh" }}
      >
        {isSmall && (
          <header className="flex items-center justify-between px-4 py-4">
            <IconButton className="-ml-2" onClick={() => setOpen(true)}>
              <HiMenu />
            </IconButton>
          </header>
        )}
        <div className="p-6">
          <div className="flex items-center justify-between">
            <div className="font-semibold text-xl">Closings</div>
          </div>

          <div className="py-6">
            <div className="rounded-xl shadow-sm bg-white md:col-span-12">
              <div className="p-4 overflow-x-scroll">
                <table className="min-w-full">
                  <thead className="text-sm bg-gray-50 text-gray-500">
                    <tr className="text-sm text-left">
                      <th className="px-4 py-3 whitespace-nowrap">Date</th>
                      <th className="hidden md:block px-4 py-3 whitespace-nowrap">
                        ID
                      </th>
                      <th className="px-4 py-3 whitespace-nowrap">Status</th>
                      <th className="px-4 py-3 whitespace-nowrap">Amount</th>
                      <th className="px-4 py-3 whitespace-nowrap">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    <div className="w-full mx-auto text-center py-6">
                      No Closings
                    </div>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default Closings;

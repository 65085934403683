import { HiMenu } from "react-icons/hi";
import IconButton from "../../../components/IconButton";
import useBreakpoint from "@restart/hooks/useBreakpoint";
import DashboardCards from "../../../components/DashboardCards/DashboardCards";
import TransactionHistory from "../../../components/Notary/TransactionHistory/TransactionHistory";

interface DashboardProps {
  setOpen: (value: boolean) => void;
}

function Dashboard({ setOpen }: DashboardProps) {
  const isSmall = useBreakpoint("sm", "down");

  return (
    <main
      className="flex-grow bg-gray-50 text-gray-900"
      style={{ width: "60%", height: "150vh" }}
    >
      {isSmall && (
        <header className="flex items-center justify-between px-4 py-4">
          <IconButton className="-ml-2" onClick={() => setOpen(true)}>
            <HiMenu />
          </IconButton>
        </header>
      )}
      <div className={"p-6"}>
        <div className={"flex items-center justify-between"}>
          <div className="font-semibold text-xl">Home</div>
        </div>
        <div className="py-6 grid grid-cols-1 md:grid-cols-12 gap-4">
          <DashboardCards cardType="payout" value={5025} />
          <DashboardCards cardType="confirmed" value={33} />
          <DashboardCards cardType="cancelled" value={14} />
          <DashboardCards cardType="progress" value={6} />
        </div>
        <div></div>
      </div>
      <TransactionHistory setOpen={setOpen} />
    </main>
  );
}

export default Dashboard;

import { Navigate } from "react-router-dom";
import { useAuth } from "../auth/AuthContext";
import React, { useEffect, useState } from "react";

interface PrivateRouteProps {
  children: JSX.Element;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const { isAuthenticated, refreshToken } = useAuth();
  const [isChecking, setIsChecking] = useState(true);

  useEffect(() => {
    const verifyAuthentication = async () => {
      let authenticated = isAuthenticated;
      if (!authenticated) {
        authenticated = await refreshToken(); // Attempt to refresh token
      }
      setIsChecking(false);
    };

    verifyAuthentication();
  }, [isAuthenticated, refreshToken]);

  if (isChecking) {
    return <div>Loading...</div>; // Or any loading indicator
  }

  return isAuthenticated ? children : <Navigate to="/login" replace />;
};

export default PrivateRoute;

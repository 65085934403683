import axios from 'axios';
import { User } from '../types';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export default function startAuthentication(user: User) {
  const config = {
    headers: { 
      'Content-Type': 'application/json'
    },
  };

  return axios.post(`${BASE_URL}/api/token/`, JSON.stringify(user), config);
};

import { useEffect, useState } from "react";
import NotaryRegistration from "../Register";
import { Route, Routes } from "react-router-dom";
import { NavLink, useNavigate } from "react-router-dom";
import {
  HiHome,
  HiUserAdd,
  HiCalendar,
  HiDocumentText,
  HiSpeakerphone,
  HiBriefcase,
  HiCash,
} from "react-icons/hi";
// import TransactionHistory from "../../components/Notary/TransactionHistory/TransactionHistory";

import Logo from "../../components/Logo";
import Avatar from "../../components/Avatar";
import { Company, Notary } from "../../types";
import Sidebar from "../../components/Sidebar";
import { useUser } from "../../auth/UserContext";
import ProfileMenu from "../../components/ProfileMenu";
import LenderRegistration from "../../components/Lender";

import Calendar from "../Calendar";
import Closings from "../Closings";
// import getNotary from "../../actions/getNotary";
import AllNotaries from "../../components/Notary/AllNotaries";
import AdminDashboard from "../../components/Admin/Dashboard/";
import AllLenders from "../../components/Lender/AllLenders/AllLenders";
import TransactionHistory from "../../components/Notary/TransactionHistory";

interface DashboardRoutesConfigProps {
  setNotary: (value: Notary & Company) => void;
}

const links = [
  { icon: HiHome, label: "Home", to: "/admin" },
  { icon: HiDocumentText, label: "Closings", to: "/admin/closings" },
  {
    icon: HiCalendar,
    label: "Calendar of Closings",
    to: "/admin/calendar",
  },
  {
    icon: HiSpeakerphone,
    label: "Notify Closings",
    to: "/admin/notify",
  },
  {
    icon: HiCash,
    label: "Show Lenders",
    to: "/admin/lenders",
  },
  {
    icon: HiBriefcase,
    label: "Show Notaries",
    to: "/admin/notaries",
  },
  {
    icon: HiUserAdd,
    label: "Create Lender",
    to: "/admin/create-lender",
  },
  {
    icon: HiUserAdd,
    label: "Create Notary",
    to: "/admin/create-notary",
  },
];

function DashboardAdminRoutesConfig({ setNotary }: DashboardRoutesConfigProps) {
  const navigate = useNavigate();
  const { isSuperUser } = useUser();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isSuperUser) {
      navigate("/notary");
    }
  }, [isSuperUser, navigate]);

  return (
    <div className="flex">
      <Sidebar
        open={open}
        setOpen={setOpen}
        title={
          <div className="flex items-center">
            <Avatar bg="primary" className="mr-3">
              <Logo size={24} />
            </Avatar>{" "}
            Admin
          </div>
        }
      >
        <nav className="flex flex-wrap flex-col">
          {links.map((link) => (
            <NavLink
              key={link.to}
              onClick={() => setOpen(false)}
              to={link.to}
              className={
                "flex items-center font-medium rounded-xl px-4 py-4 text-gray-500"
              }
            >
              <link.icon className="mr-4" size={20} />
              {link.label}
            </NavLink>
          ))}
        </nav>
        <span className="flex-grow" />
        <div className="flex items-center"></div>
        <ProfileMenu />
      </Sidebar>
      <Routes>
        <Route index element={<AdminDashboard setOpen={setOpen} />} />
        <Route
          path="/notify"
          element={<TransactionHistory setOpen={setOpen} />}
        />
        <Route path="/closings" element={<Closings setOpen={setOpen} />} />
        <Route path="/lenders" element={<AllLenders setOpen={setOpen} />} />
        <Route path="/notaries" element={<AllNotaries setOpen={setOpen} />} />
        <Route path="/calendar" element={<Calendar setOpen={setOpen} />} />
        <Route path="/create-lender" element={<LenderRegistration admin />} />
        <Route
          path="/create-notary"
          element={<NotaryRegistration setUser={() => {}} isAdmin />}
        />
      </Routes>
    </div>
  );
}

export default DashboardAdminRoutesConfig;

import Logo from "../Logo";
import Avatar from "../Avatar";

function Header() {
  return (
    <header className="">
      <nav
        className="mx-auto flex max-w-screen-xl items-center justify-between py-6"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Fast Doc Solutions</span>
            <Avatar bg="primary" className="mr-3">
              <Logo size={24} />
            </Avatar>
            <h1 className="text-3xl inline font-bold">
              {" Fast Doc Solutions"}
            </h1>
          </a>
        </div>

        <div className=" lg:justify-end">
          <a
            href="/login"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;

import * as yup from "yup";
import { useState } from "react";
import { toast } from "react-toastify";
import { Loader } from "semantic-ui-react";
import Header from "../../components/Header";
import { registerLender } from "../../actions/register";
import useBreakpoint from "@restart/hooks/useBreakpoint";
import { Formik, Form, ErrorMessage, Field } from "formik";

import "semantic-ui-css/semantic.min.css";
import "semantic-ui-css/components/icon.min.css";
import "semantic-ui-css/components/item.min.css";
import "semantic-ui-css/components/list.min.css";
import "semantic-ui-css/components/menu.min.css";
import "semantic-ui-css/components/label.min.css";
import "semantic-ui-css/components/dropdown.min.css";
import "semantic-ui-css/components/transition.min.css";

interface RegisterProps {
  admin?: boolean;
}

const validationSchema = yup.object({
  name: yup.string().required("This field is required"),
  email_address: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  confirm_email: yup
    .string()
    .oneOf([yup.ref("email_address"), undefined], "Emails don't match")
    .required("This field is required"),
  password: yup.string().required("Password required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), undefined], "Passwords don't match")
    .required("This field is mandatory"),
  street_address: yup.string().required("Address required"),
  city: yup.string().required("City is required"),
  state: yup.string(),
  zip_code: yup
    .string()
    .min(5, "Must be 5 digits long")
    .required("Zip Code is required"),
});

const initialValues = {
  name: "",
  email_address: "",
  confirm_email: "",
  password: "",
  confirm_password: "",
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
};

function Register(admin: RegisterProps) {
  const [contactState, setContactState] = useState("AL");
  const [submitting, setSubmitting] = useState(false);
  const isSmall = useBreakpoint("sm", "down");
  const accessToken = localStorage.getItem("accessToken");

  function handleSubmit(values: any) {
    setSubmitting(true);
    const payload = {
      name: values.name,
      address:
        values.street_address +
        ", " +
        values.state +
        values.city +
        ", " +
        values.zip_code,
      account_balance: 0,
    };

    registerLender(payload, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((e) => {
        toast.success("Lender successfully created!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          setSubmitting(false);
        }, 3000);
      })
      .catch((e) => {
        setSubmitting(false);
        toast.error(
          `Something is wrong: ` + getErrorMessage(e.response?.data),
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      });
  }

  function getErrorMessage(error: any) {
    if (!error) {
      return "please try again!";
    }

    return "please try again!";
  }

  return (
    <div className={`${admin ? "w-full" : "grid grid-cols-12 gap-0"}`}>
      <div
        className={`${
          admin ? "hidden" : "h-auto col-span-12 md:col-span-4 bg-sky-50"
        }`}
      >
        <div className="flex flex-col md:h-screen justify-between mx-16 mt-4">
          <Header />
          <main className="mb-6 md:mb-auto md:h-10">
            <div className="flex text-3xl font-bold w-75 flex-wrap max-w-sm text-slate-400">
              Let's get started on your Lender Account
            </div>
          </main>
          <footer className="mb-6 md:h-10"></footer>
        </div>
      </div>

      <div className={`${admin ? "col-span-12" : "col-span-12 md:col-span-8"}`}>
        {submitting ? (
          <div
            className={`col-span-12 md:col-span-8`}
            style={{ maxWidth: "1000px" }}
          >
            <Loader
              style={{
                display: "block",
                left: `${isSmall ? "50%" : "65%"}`,
                maxWidth: "1000px",
              }}
            >
              <div>{"Creating your Account..."}</div>
            </Loader>
          </div>
        ) : (
          <div
            className="m-8 bg-white rounded-lg px-6 py-8 ring-1 ring-slate-900/5 shadow-xl"
            style={{ maxWidth: `${admin ? "100%" : "1000px"}` }}
          >
            <Formik
              initialValues={initialValues}
              onSubmit={handleSubmit}
              validationSchema={validationSchema}
            >
              {({ values, setFieldValue }) => (
                <Form>
                  <div className="space-y-12">
                    <div className="border-b border-gray-900/10 pb-12">
                      <h2 className="text-base font-semibold leading-7 text-gray-900">
                        Create your Lender Account
                      </h2>
                      <p className="mt-1 text-sm leading-6 text-gray-600">
                        If you are approved, you will be able to{" "}
                        <span className="font-bold">Approve Documents.</span>
                      </p>

                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="col-span-full">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Lender Name
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="name"
                              id="name"
                              autoComplete="given-name"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="name" />
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="email_address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Contact Email Address
                          </label>
                          <div className="mt-2">
                            <Field
                              id="email_address"
                              name="email_address"
                              type="email"
                              autoComplete="email_address"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="email_address" />
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="confirm_email"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Confirm Contact Email Address
                          </label>
                          <div className="mt-2">
                            <Field
                              id="confirm_email"
                              name="confirm_email"
                              type="email"
                              autoComplete="confirm_email"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="confirm_email" />
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Password
                          </label>
                          <div className="mt-2">
                            <Field
                              type="password"
                              name="password"
                              id="password"
                              autoComplete="given-name"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="password" />
                          </span>
                        </div>

                        <div className="sm:col-span-3">
                          <label
                            htmlFor="confirm_password"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Confirm Password
                          </label>
                          <div className="mt-2">
                            <Field
                              type="password"
                              name="confirm_password"
                              id="confirm_password"
                              autoComplete="family-name"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="confirm_password" />
                          </span>
                        </div>

                        <div className="col-span-full">
                          <label
                            htmlFor="street_address"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Lender Address
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="street_address"
                              id="street_address"
                              autoComplete="street_address"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="street_address" />
                          </span>
                        </div>

                        <div className="sm:col-span-2 sm:col-start-1">
                          <label
                            htmlFor="city"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            City
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="city"
                              id="city"
                              autoComplete="city"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="city" />
                          </span>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="state"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            State
                          </label>
                          <div className="mt-2">
                            <Field
                              name="state"
                              as="select"
                              value={contactState}
                              onChange={(e: any) =>
                                setContactState(e.target.value)
                              }
                              className="h-9 block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            >
                              <option selected value="AL">
                                Alabama
                              </option>
                              <option value="AK">Alaska</option>
                              <option value="AZ">Arizona</option>
                              <option value="AR">Arkansas</option>
                              <option value="CA">California</option>
                              <option value="CO">Colorado</option>
                              <option value="CT">Connecticut</option>
                              <option value="DE">Delaware</option>
                              <option value="DC">District Of Columbia</option>
                              <option value="FL">Florida</option>
                              <option value="GA">Georgia</option>
                              <option value="HI">Hawaii</option>
                              <option value="ID">Idaho</option>
                              <option value="IL">Illinois</option>
                              <option value="IN">Indiana</option>
                              <option value="IA">Iowa</option>
                              <option value="KS">Kansas</option>
                              <option value="KY">Kentucky</option>
                              <option value="LA">Louisiana</option>
                              <option value="ME">Maine</option>
                              <option value="MD">Maryland</option>
                              <option value="MA">Massachusetts</option>
                              <option value="MI">Michigan</option>
                              <option value="MN">Minnesota</option>
                              <option value="MS">Mississippi</option>
                              <option value="MO">Missouri</option>
                              <option value="MT">Montana</option>
                              <option value="NE">Nebraska</option>
                              <option value="NV">Nevada</option>
                              <option value="NH">New Hampshire</option>
                              <option value="NJ">New Jersey</option>
                              <option value="NM">New Mexico</option>
                              <option value="NY">New York</option>
                              <option value="NC">North Carolina</option>
                              <option value="ND">North Dakota</option>
                              <option value="OH">Ohio</option>
                              <option value="OK">Oklahoma</option>
                              <option value="OR">Oregon</option>
                              <option value="PA">Pennsylvania</option>
                              <option value="RI">Rhode Island</option>
                              <option value="SC">South Carolina</option>
                              <option value="SD">South Dakota</option>
                              <option value="TN">Tennessee</option>
                              <option value="TX">Texas</option>
                              <option value="UT">Utah</option>
                              <option value="VT">Vermont</option>
                              <option value="VA">Virginia</option>
                              <option value="WA">Washington</option>
                              <option value="WV">West Virginia</option>
                              <option value="WI">Wisconsin</option>
                              <option value="WY">Wyoming</option>
                            </Field>
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="state" />
                          </span>
                        </div>

                        <div className="sm:col-span-2">
                          <label
                            htmlFor="zip_code"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            ZIP Code
                          </label>
                          <div className="mt-2">
                            <Field
                              type="text"
                              name="zip_code"
                              id="zip_code"
                              autoComplete="zip_code"
                              className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                          <span className="text-xs font-bold text-red-500">
                            <ErrorMessage name="zip_code" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mt-6 flex items-center justify-end gap-x-6">
                    <button
                      type="button"
                      className="text-sm font-semibold leading-6 text-gray-900"
                      onClick={() => {
                        window.history.go(-1);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                    >
                      Save
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </div>
    </div>
  );
}

export default Register;

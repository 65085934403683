import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect,
  useCallback,
} from "react";
import axios from "axios";

type AuthContextType = {
  isAuthenticated: boolean;
  login: (accessToken: string, refreshToken: string) => void;
  logout: () => void;
  refreshToken: () => Promise<boolean>;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

type AuthProviderProps = {
  children: ReactNode;
};

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [accessToken, setAccessToken] = useState<string | null>(() =>
    localStorage.getItem("accessToken")
  );

  const login = useCallback(
    (newAccessToken: string, newRefreshToken: string) => {
      localStorage.setItem("accessToken", newAccessToken);
      localStorage.setItem("refreshToken", newRefreshToken);
      setAccessToken(newAccessToken);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newAccessToken}`;
    },
    []
  );

  const logout = useCallback(() => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    setAccessToken(null);
    window.location.href = "/login";
    delete axios.defaults.headers.common["Authorization"];
  }, []);

  const refreshToken = useCallback(async (): Promise<boolean> => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      logout();
      return false;
    }
    try {
      const response = await axios.post(`${BASE_URL}/api/token/refresh/`, {
        refresh: refreshToken,
      });
      if (response.status === 200) {
        const { access } = response.data;
        login(access, refreshToken);
        return true;
      } else {
        logout();
        return false;
      }
    } catch (error) {
      console.error("Refresh token failed:", error);
      logout();
      return false;
    }
  }, [login, logout]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      (response) => response,
      async (error) => {
        if (error.response && error.response.status === 401) {
          const originalRequest = error.config;
          if (
            !originalRequest._retry &&
            !originalRequest.url.includes(`${BASE_URL}/api/token/refresh/`)
          ) {
            originalRequest._retry = true;
            const success = await refreshToken();
            if (success) {
              originalRequest.headers[
                "Authorization"
              ] = `Bearer ${localStorage.getItem("accessToken")}`;
              return axios(originalRequest);
            }
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup the interceptor to avoid memory leaks
    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [refreshToken]);

  useEffect(() => {
    if (accessToken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [accessToken]);

  return (
    <AuthContext.Provider
      value={{ isAuthenticated: !!accessToken, login, logout, refreshToken }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) throw new Error("useAuth must be used within an AuthProvider");
  return context;
};

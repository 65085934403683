import { useState } from "react";
import Closings from "../Closings";
import Calendar from "../Calendar";
import Dashboard from "./Dashboard";
import Logo from "../../components/Logo";
import { NavLink } from "react-router-dom";
import Avatar from "../../components/Avatar";
import { Company, Notary } from "../../types";
import Sidebar from "../../components/Sidebar";
import { Route, Routes } from "react-router-dom";
import ProfileMenu from "../../components/ProfileMenu";
import NotaryProfile from "../NotaryProfile/NotaryProfile";
import {
  HiHome,
  HiPencilAlt,
  HiCalendar,
  HiDocumentText,
} from "react-icons/hi";
import TransactionHistory from "../../components/Notary/TransactionHistory";

// import Calendar from "../Calendar";
// import Closings from "../Closings";
// import getNotary from "../../actions/getNotary";
// import NotaryProfile from "../NotaryProfile/NotaryProfile";

interface DashboardRoutesConfigProps {
  setNotary: (value: Notary & Company) => void;
}

const links = [
  { icon: HiHome, label: "Home", to: "/notary/dashboard" },
  { icon: HiDocumentText, label: "Closings", to: "/notary/closings" },
  {
    icon: HiCalendar,
    label: "Calendar of Closings",
    to: "/notary/calendar",
  },
  {
    icon: HiPencilAlt,
    label: "Transaction History",
    to: "/notary/transaction",
  },
];

function DashboardRoutesConfig({ setNotary }: DashboardRoutesConfigProps) {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex">
      <Sidebar
        open={open}
        setOpen={setOpen}
        title={
          <div className="flex items-center">
            <Avatar bg="primary" className="mr-3">
              <Logo size={24} />
            </Avatar>{" "}
            Notary
          </div>
        }
      >
        <nav className="flex flex-wrap flex-col">
          {links.map((link) => (
            <NavLink
              key={link.to}
              onClick={() => setOpen(false)}
              to={link.to}
              className={
                "flex items-center font-medium rounded-xl px-4 py-4 text-gray-500"
              }
            >
              <link.icon className="mr-4" size={20} />
              {link.label}
            </NavLink>
          ))}
        </nav>
        <span className="flex-grow" />
        <div className="flex items-center"></div>
        <ProfileMenu />
      </Sidebar>
      <Routes>
        <Route index element={<Dashboard setOpen={setOpen} />} />
        <Route path="/dashboard" element={<Dashboard setOpen={setOpen} />} />
        <Route
          path="/transaction"
          element={<TransactionHistory setOpen={setOpen} />}
        />
        <Route path="/closings" element={<Closings setOpen={setOpen} />} />
        <Route path="/calendar" element={<Calendar setOpen={setOpen} />} />
        <Route path="/profile" element={<NotaryProfile setOpen={setOpen} />} />
      </Routes>
    </div>
  );
}

export default DashboardRoutesConfig;

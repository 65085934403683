import IconButton from "../../components/IconButton";
import { HiMenu } from "react-icons/hi";
import useBreakpoint from "@restart/hooks/useBreakpoint";
// import TransactionHistory from "../../components/Notary/TransactionHistory/TransactionHistory";
import DashboardCards from "../../components/DashboardCards/DashboardCards";

interface DashboardProps {
  setOpen: (value: boolean) => void;
}

function Dashboard({ setOpen }: DashboardProps) {
  const isSmall = useBreakpoint("sm", "down");

  return (
    <>
      <main
        className="flex-grow bg-gray-50 text-gray-900"
        style={{ width: "60%", height: "150vh" }}
      >
        {isSmall && (
          <header className="flex items-center justify-between px-4 py-4">
            <IconButton className="-ml-2" onClick={() => setOpen(true)}>
              <HiMenu />
            </IconButton>
          </header>
        )}
        <div className={"p-6"}>
          <div className={"flex items-center justify-between"}>
            <div className="font-semibold text-xl">Home</div>
          </div>
          <div className={"py-6 grid grid-cols-1 md:grid-cols-12 gap-4"}>
            <DashboardCards cardType="income" value={5025} />
            <DashboardCards cardType="confirmed" value={50} />
            <DashboardCards cardType="cancelled" value={3} />
            <DashboardCards cardType="progress" value={5} />
          </div>
        </div>
        {/* <TransactionHistory setOpen={setOpen} /> */}
      </main>
    </>
  );
}

export default Dashboard;

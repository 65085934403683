import axios from 'axios';
import { Lender } from '../types';

// Use the base URL from the environment variable
const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// Reusable config for requests with default headers
const defaultConfig = {
  headers: { 
    'Content-Type': 'application/json',
  },
};

// Register notary
export function registerNotary(notary: any) {
  return axios.post(`${BASE_URL}/notary/register/`, JSON.stringify(notary), defaultConfig);
}

// Register user
export function registerUser(user: any) {
  return axios.post(`${BASE_URL}/users/`, JSON.stringify(user), defaultConfig);
}

// Register employee
type Employee = {
  phone: string;
  role: string;
  start_date: string;
  user: {
    first_name: string;
    last_name: string;
    email_address: string;
    username: string;
    password: string;
  };
};

export function registerEmployee(employee: Employee) {
  return axios.post(`${BASE_URL}/employee/register/`, JSON.stringify(employee), defaultConfig);
}

// Register lender (additional custom headers if required)
export function registerLender(lender: Lender, reqHeaders: any = defaultConfig) {
  return axios.post(`${BASE_URL}/lenders/`, JSON.stringify(lender), reqHeaders);
}
